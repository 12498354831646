<template>
  <div class="main-blank">
    <div class="main-header">
      <main-header />
    </div>
    <div class="main-content">
      <router-view />
    </div>
    <div class="main-footer">
      <main-footer />
    </div>
    <div class="nav" ref="mainNav">
      <div class="nav-blank">
        <div class="nav-item nav-item-first" :class="{'nav-item-selected' : navIndex === 1}"  @click="navIndex = 1">
          <img src="../assets/img/nav_contact_icon.png" />
          <span>在线咨询</span>
          <div v-if="navIndex === 1" class="code-wrap">
            <div class="code-image">
              <img :src="configInfo.kefu_wechat_image" />
            </div>
            <span>微信扫码</span>
            <p v-if="configInfo.kefu_mobile">微信号：{{ configInfo.kefu_mobile }}</p>
            <img @click.stop="closeNav" class="close-image" src="../assets/img/code_close_icon.png" />
          </div>
        </div>
        <div class="nav-item nav-item-second" :class="{'nav-item-selected' : navIndex === 2}" @click="navIndex = 2">
          <img src="../assets/img/nav_wx_icon.png" />
          <span>公众号</span>
          <div v-if="navIndex === 2" class="code-wrap">
            <div class="code-image">
              <img :src="configInfo.wechat_image" />
            </div>
            <span>微信扫码关注泰祺</span>
            <span>获取学习干货</span>
            <img @click.stop="closeNav" class="close-image" src="../assets/img/code_close_icon.png" />
          </div>
        </div>
        <div class="nav-item nav-item-third" @click="toDownloadUrl">
          <img src="../assets/img/nav_app_icon.png" />
          <span>下载APP</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainHeader from '../components/header';
import MainFooter from '../components/footer';
import * as codeService from '@/service/code-service';
import * as loginService from '@/service/login-service';
import * as Utils from '@/utils/index';
export default {
  name: 'Main',
  props: {
    navShow: {
      type: Boolean,
      default: true
    }
  },
  components: {
    MainHeader,
    MainFooter
  },
  data() {
    return {
      configInfo: {}, // 配置信息
      navIndex: 0
    };
  },
  watch: {
    $route() {
      this.navIndex = 0;
    }
  },
  mounted() {
    this.getPCConfig();
    if (Utils.getLoginCondition()) {
      this.getUserInfo();
    }
  },
  methods: {
    /**
     * 获取pc端配置信息
     * */
    getPCConfig() {
      codeService.getPCConfig().then(res => {
        if (res.code === 1) {
          this.configInfo = res.data;
          Utils.setLocalStorage('config_info', this.configInfo);
        }
      });
    },
    /**
     * 获取用户信息
     * */
    getUserInfo() {
      loginService.getUserInfo().then(res => {
        if (res.code === 1) {
          Utils.setLocalStorage('LoginUser', res.data);
        }
      });
    },
    toDownloadUrl() {
      this.navIndex = 0;
      this.$router.push({
        path: '/appDownload'
      });
    },
    closeNav() {
      this.navIndex = 0;
    }
  }
};
</script>

<style scoped lang="scss">
.main-blank{
  min-height: 100vh;
  min-width: 1200px;
  background: #F7F9FB;
  .main-header {
    height: 80px;
    background: #fff;
  }
  .main-content {
    display: flex;
    justify-content: center;
    min-height: calc( 100vh - 315px );
  }
  .main-footer {
    height: 235px;
  }
  ::v-deep .el-pagination {
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
  }
  ::v-deep .el-pagination.is-background .btn-next, ::v-deep .el-pagination.is-background .btn-prev, ::v-deep .el-pagination.is-background .el-pager li {
    min-width: 34px;
    height: 34px;
    line-height: 34px;
    background-color: #FAFAFA;
    border-radius: 2px;
  }
  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: #2869F5;
  }
  .nav {
    position: fixed;
    right: 0;
    bottom: calc( 50vh - 165px );
    z-index: 1000;
    .nav-blank {
      display: flex;
      flex-direction: column;
      width: 98px;
      height: 330px;
      background: #FFFFFF;
      border-radius: 10px 0px 0px 10px;
      overflow: hidden;
      .nav-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        line-height: 1;
        cursor: pointer;
        >img {
          width: 46px;
          height: 46px;
          margin-bottom: 10px;
        }
        >span {
          font-size: 14px;
          font-weight: 400;
          color: #555555;
        }
      }
      .nav-item-first {
        height: 115px;
        padding-top: 30px;
      }
      .nav-item-selected {
        background: rgba(47, 112, 252, 0.15);
      }
      .nav-item-second {
        height: 100px;
        padding-top: 15px;
        .code-wrap {
          span+span {
            margin-top: 5px;
          }
        }
      }
      .nav-item-third {
        height: 115px;
        padding-top: 15px;
      }
    }
    .code-wrap {
      position: absolute;
      top: 0;
      right: 110px;
      width: 210px;
      height: 300px;
      background: #FFFFFF;
      box-shadow: 0px 0px 16px 0px rgba(47,112,252,0.16);
      border-radius: 10px;
      padding: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      line-height: 1;
      .code-image {
        width: 180px;
        height: 180px;
        background: #FFFFFF;
        border: 1px solid #FAFAFA;
        border-radius: 6px 0px 0px 0px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      span {
        margin-top: 15px;
        font-size: 16px;
        color: #222222;
      }
      p {
        margin-top: 10px;
        font-size: 14px;
        color: #666666;
      }
      .close-image {
        margin-top: 15px;
        width: 21px;
        height: 21px;
        cursor: pointer;
      }
    }
  }
}
</style>
