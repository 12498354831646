import { http, Problem } from '../utils/httpRequest';
/**
 * 获取客服二维码图片
 * */
export function getKefuCode() {
  return http({
    url: '/api/common/kefucode',
    method: 'POST'
  }).then(result => {
    return result.data;
  }).catch(error => {
    return Problem(error);
  });
};

/**
 * 获取pc端配置信息
 * */
export function getPCConfig() {
  return http({
    url: '/api/common/getPcConfig',
    method: 'GET'
  }).then(result => {
    return result.data;
  }).catch(error => {
    return Problem(error);
  });
}

/**
 * 获取导航条列表/api/common/getNavList
 * */
export function getNavList() {
  return http({
    url: '/api/common/getNavList',
    method: 'GET'
  }).then(result => {
    return result.data;
  }).catch(error => {
    return Problem(error);
  });
}
