<template>
  <div class="content-wrap">
    <div class="header-left">
      <div class="logo-blank" @click="toHome()">
        <img src="../assets/img/logo.png">
        <span>泰祺考研</span>
      </div>
      <div class="header-function">
        <span :class="{'function-selected' : curIndex === index}" v-for="(value, index) in functionData" :key="index" @click="toNavUrl(value.jump_url)">{{ value.name }}</span>
      </div>
      <div class="header-search">
        <div class="search-input">
          <input v-model="searchKeywords" placeholder="请输入搜索内容" @keyup.enter.prevent="toNavSearch()" />
          <div v-if="searchKeywords.length > 0" class="search-close">
            <img @click="searchKeywords = ''" src="../assets/img/search_close_icon.png" />
            <div class="line" />
          </div>
        </div>
        <div class="search-btn" @click.prevent="toNavSearch()">
          <img src="../assets/img/search.png"/>
        </div>
      </div>
    </div>
    <div class="header-right">
      <div v-if="loginCondition" class="header-user">
        <div class="user-course" @click="toNavUrl('/myLearning')">我的学习</div>
        <div class="user-blank">
          <img v-if="userInfo.avatar.indexOf('http') !== -1" class="user-actor" :src="userInfo.avatar" />
          <img v-else class="user-actor" src="../assets/img/avtar_icon.jpg" />
          <span class="name">{{ userInfo.nickname }}</span>
<!--          <img class="user-down" src="../assets/img/header_down.png" />-->
          <div class="exit-blank">
            <span @click="exitLogin()">退出登录</span>
          </div>
        </div>
      </div>
      <div v-else class="login-blank" @click="toNavUrl('/login')">登录/注册</div>
    </div>
  </div>
</template>

<script>
import * as Utils from '@/utils/index';
import * as codeService from '@/service/code-service';
export default {
  name: 'MainHeader',
  data() {
    return {
      loginCondition: false,
      functionData: [
        {
          name: '首页',
          jump_url: '/'
        },
        {
          name: '选课',
          jump_url: '/courseSelection'
        },
        {
          name: 'APP下载',
          jump_url: '/appDownload'
        }
      ],
      curIndex: 0,
      searchKeywords: '', // 搜索内容
      userInfo: {} // 用户信息
    };
  },
  watch: {
    $route(to) {
      this.loginCondition = Utils.getLoginCondition();
      this.userInfo = Utils.getLocalStorage('LoginUser');
      if (to.meta.index || to.path === '/' || to.path === '/home') {
        this.curIndex = to.meta.index;
      }
      else {
        this.curIndex = -1;
      }
      if (to.path !== '/search') {
        this.searchKeywords = '';
      }
    }
  },
  created() {
    this.loginCondition = Utils.getLoginCondition();
    this.userInfo = Utils.getLocalStorage('LoginUser');
    if (this.$route.meta.index || this.$route.path === '/' || this.$route.path === '/home') {
      this.curIndex = this.$route.meta.index;
    }
    else {
      this.curIndex = -1;
    }
    this.getNavList();
  },
  methods: {
    getNavList() {
      codeService.getNavList().then(res => {
        if (res.code === 1) {
          this.functionData = this.functionData.concat(res.data);
        }
      });
    },
    /**
     * 搜索
     * */
    toNavSearch() {
      this.$router.push({
        path: '/search',
        query: {
          keywords: this.searchKeywords
        }
      });
    },
    toNavUrl(path) {
      if (path.indexOf('http') !== -1) {
        window.open(path, '_blank');
        return;
      }
      this.$router.push({
        path
      });
    },
    /**
     * 退出登录
     * */
    exitLogin() {
      Utils.clearLoginInfo();
      this.loginCondition = Utils.getLoginCondition();
      this.$router.push({
        path: '/login'
      });
    },
    /**
     * 跳转首页
     * */
    toHome() {
      this.$router.push({
        path: '/'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.content-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  .header-left {
    display: flex;
    align-items: center;
    flex: 1;
    margin-right: 20px;
    .logo-blank {
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        width: 43px;
        height: 43px;
        margin-right: 12px;
      }
      span {
        font-size: 28px;
        font-weight: bold;
        color: #1E66FF;
      }
    }
    .header-function {
      flex: 1;
      margin-left: 42px;
      span {
        cursor: pointer;
        font-size: 16px;
        font-weight: 400;
        color: #222222;
        margin-right: 39px;
        position: relative;
        padding-bottom: 12px;
        text-align: center;
      }
      span:hover:after {
        position: absolute;
        left: calc( 50% - 13px );
        bottom: 0;
        content: '';
        width: 26px;
        height: 4px;
        background: #2869F5;
        border-radius: 2px;
      }
      span:last-child {
        margin-right: 37px;
      }
      .function-selected:after {
        position: absolute;
        left: calc( 50% - 13px );
        bottom: 0;
        content: '';
        width: 26px;
        height: 4px;
        background: #2869F5;
        border-radius: 2px;
      }
    }
    .header-search {
      display: flex;
      width: 250px;
      height: 44px;
      background: #FFFFFF;
      border: 1px solid #2869F5;
      border-radius: 21px;
      padding: 4px 4px 4px 21px;
      .search-input {
        display: flex;
        justify-content: space-between;
        flex: 1;
        input {
          width: 129px;
          border: none;
          display: inline-block;
        }
        .search-close {
          display: flex;
          width: 25px;
          margin-right: 15px;
          img {
            width: 17px;
            height: 17px;
            margin-top: 8.5px;
            margin-right: 7px;
            cursor: pointer;
          }
          .line {
            margin-top: 9.5px;
            width: 1px;
            height: 15px;
            background: #DADDE3;
            border-radius: 1px;
          }
        }
        input::placeholder {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
        }
      }
      .search-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 46px;
        height: 34px;
        background: #2869F5;
        border-radius: 17px;
        cursor: pointer;
        img {
          width: 19px;
          height: 19px;
        }
      }
    }
  }
  .header-right {
    display: flex;
    align-items: center;
    .login-blank {
      font-size: 16px;
      font-weight: 500;
      color: #2869F5;
      margin-right: 43px;
      cursor: pointer;
    }
    .header-user {
      display: flex;
      align-items: center;
      .user-course {
        font-size: 16px;
        color: #333333;
        margin-right: 29px;
        cursor: pointer;
      }
      .user-blank {
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        height: 80px;
        .user-actor {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          margin-right: 9px;
        }
        .name {
          display: inline-block;
          min-width: 98px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 16px;
          font-weight: 400;
          color: #333333;
        }
        .user-down {
          width: 13px;
          height: 8px;
        }
        .exit-blank {
          position: absolute;
          top: 66px;
          left: 3px;
          display: none;
          justify-content: center;
          align-items: center;
          width: 140px;
          height: 49px;
          background: #FFFFFF;
          border: 1px solid #EBEEF5;
          border-radius: 4px;
          z-index: 10;
          span {
            cursor: pointer;
            font-size: 14px;
            color: #666666;
          }
        }
        .exit-blank:before{
          position: absolute;
          content: '';
          left: calc(50% - 6px);
          top: -6px;
          width: 0;
          height: 0;
          border-width: 6px;
          border-color: transparent;
          border-style: solid;
          border-top-width: 0;
          border-bottom-color: #ebeef5;
        }
        .exit-blank:after{
          position: absolute;
          content: '';
          left: 50%;
          width: 0;
          height: 0;
          border-width: 6px;
          border-color: transparent;
          border-style: solid;
          top: -5px;
          margin-left: -6px;
          border-top-width: 0;
          border-bottom-color: #fff;
        }
      }
      .user-blank:hover {
        .exit-blank {
          display: flex;
        }
      }
    }
  }
}
</style>
