<template>
  <div class="footer-blank">
    <div class="top-blank">
      <div class="content-wrap">
        <div class="left">
          <span @click="toNavUrl('/aboutUs')">关于我们</span>
          <span @click="toNavUrl('/contactUs')">联系我们</span>
        </div>
        <div class="right">
          <div class="content">
            <div>
              <img :src="configInfo.android_image">
            </div>
            <span>扫码下载APP</span>
          </div>
          <div class="content">
            <div>
              <img :src="configInfo.wechat_image">
            </div>
            <span>扫码关注公众号</span>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-blank">
      <div class="content-wrap">
        <p>Copyright © 上海泰涌教育信息咨询有限公司 All right reserved.<span class="footer-bah" @click="toBHUrl()">沪ICP备2023025547号-1</span> </p>
        <p>技术支持：江苏万禾科技集团有限公司</p>
      </div>
    </div>
  </div>
</template>

<script>
import * as Utils from '@/utils/index';
export default {
  name: 'MainFooter',
  data() {
    return {
      configInfo: {}
    };
  },
  mounted() {
    this.configInfo = Utils.getLocalStorage('config_info');
    if (!this.configInfo) {
      setTimeout(() => {
        this.configInfo = Utils.getLocalStorage('config_info');
      }, 2000);
    }
  },
  methods: {
    toNavUrl(path) {
      this.$router.push({
        path
      });
    },
    toBHUrl() {
      window.open('http://beian.miit.gov.cn/');
    }
  }
};
</script>

<style lang="scss" scoped>
.footer-blank {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 235px;
  background: url('../assets/img/footer_bg.png') no-repeat;
  background-size: 100% 100%;
  .top-blank {
    flex: 1;
    border-bottom: 1px solid #7D8494;
    .content-wrap {
      display: flex;
      justify-content: space-between;
      height: 100%;
    }
    .left {
      display: flex;
      align-items: center;
      height: 100%;
      span {
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 22px;
        margin-right: 50px;
        cursor: pointer;
      }
    }
    .right {
      display: flex;
      align-items: center;
      height: 100%;
      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 39px;
        div {
          width: 80px;
          height: 80px;
          background: #fff;
          margin-bottom: 11px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        span {
          font-size: 14px;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.8);
          line-height: 22px;
        }
      }
      .content+.content {
        margin-right: 30px;
      }
    }
  }
  .bottom-blank {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 90px;
    p {
      font-size: 14px;
      font-weight: 400;
      color: #A3AABA;
      line-height: 22px;
      text-align: center;
    }
    p+p {
      margin-top: 11px;
    }
    .footer-bah {
      margin-left: 20px;
      cursor: pointer;
    }
  }
}
</style>
